<template>
  <div class="root-page main-page" :class="{'main-page--cobrowse': cobrowseManager.activeRoom}">

    <ImpersonateWarning></ImpersonateWarning>

    <div class="main-page__wrapper">
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </div>

    <transition name="slideRight" mode="out-in">
      <RoomWidget v-if="cobrowseManager.activeRoom" :room="cobrowseManager.activeRoom"></RoomWidget>
    </transition>

  </div>
</template>


<script lang="ts">
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import MainBar from "@/_view/menu/MainMenuBar.vue";
import AudienceListModel from "@/audience/_model/AudienceListModel";
import ContentModel from "@/content/_model/ContentModel";
import {EventBus} from "@/__libs/vue/EventBus";
import {EventBusActions, LocalStorageKey, Runtime} from "@/_model/app.constants";
import {EntityType} from "@/entity/_model/entity.constants";
import {EngineMode} from "@/presentation/player/_model/player.constants";
import {RoutingIdentifier} from "@/router";
import EntityModel from "@/entity/_model/EntityModel";
import EntityControllerFactory from "@/entity/_controller/EntityControllerFactory";
import ShareWithTeamsBox from "@/entity/_view/ShareWithTeamsBox.vue";
import ShareWithAudienceBox from "@/content/_view/ShareWithAudienceBox.vue";
import EditContentFileBox from "@/content_file/_view/EditContentFileBox.vue";
import ContentFileModel from "@/content_file/_model/ContentFileModel";
import contentFileController from "@/content_file/_controller/ContentFileController";
import contentAppController from "@/content_app/_controller/ContentAppController";
import audienceSessionController from "@/audience/session/_controller/AudienceSessionController";
import fileManager from "@/_controller/FileManager";
import {SessionMode} from "@/audience/session/_model/audience_session.constants";
import {ActivityType} from "@/audience/track/_model/audience_activity.constants";
import appUserController from "@/project/user/_controller/AppUserController";
import ContentAppModel from "@/content_app/_model/ContentAppModel";
import {ContentAppType} from "@/content_app/_model/content_app.constants";
import {FILE_TYPE_CONFIG, FileType, IFileTypeConfig} from "@/asset_folder/_model/asset_folder.constants";
import AssetFolderListModel from "@/asset_folder/_model/AssetFolderListModel";
import FileUtil from "@/__libs/utility/FileUtil";
import EditContentAppBox from "@/content_app/_view/EditContentAppBox.vue";
import ContentFolderModel from "@/content/_model/ContentFolderModel";
import CreateEditContentFolderBox from "@/content/_view/CreateEditContentFolderBox.vue";
import contentFolderController from "@/content/_controller/ContentFolderController";
import AudienceModel from "@/audience/_model/AudienceModel";
import ContentListModel from "@/content/_model/ContentListModel";
import AppModel from "@/_model/AppModel";
import {IDeepLinkParamsDto} from "@/_model/app.dto";
import LocalStorageManager from "@/__libs/offline_storage/LocalStorageManager";
import EntityInfoBox from "@/entity/_view/EntityInfoBox.vue";
import contentController from "@/content/_controller/ContentController";
import IntroductionBox from "@/help/introduction/_view/IntroductionBox.vue";
import JsonUtil from "@/__libs/utility/JsonUtil";
import UpdateBox from "@/_view/components/UpdateBox.vue";
import AppUserModel from "@/project/user/_model/AppUserModel";
import {TierType} from "@/project/_model/project.constants";
import {GamificationTargetIdentifier} from "@/gamification/_model/gamification.constants";

import ContentFolderListModel from "@/content/_model/ContentFolderListModel";
import EntityTranslationBox from "@/entity/_view/EntityTranslationBox.vue";
import CobrowseManager from "@sales-drive/audience-library/lib/cobrowse_manager/CobrowseManager";
import RoomWidget from "@/cobrowse/room/_view/RoomWidget.vue";
import MousePointerLayer from "@/cobrowse/room/_view/MousePointerLayer.vue";
import {CobrowseRoomType} from "@sales-drive/audience-library/lib/cobrowse_manager/room/_model/room.constants";
import {
    CobrowseUserInteractionState
} from "@sales-drive/audience-library/lib/cobrowse_manager/cobrowse_user/_model/cobrowse_user.constants";
import {
    SharedObjectType
} from "@sales-drive/audience-library/lib/cobrowse_manager/shared_object/shared_object.constants";
import SharedObject from "@sales-drive/audience-library/lib/cobrowse_manager/shared_object/SharedObject";
import {
    IPresentedContentSoDataDto
} from "@sales-drive/audience-library/lib/cobrowse_manager/presented_content/_model/presented_content.dto";
import FolderInfoBox from "@/content/_view/FolderInfoBox.vue";
import ShareContentFolderWithTeamsBox from "@/content/_view/ShareContentFolderWithTeamsBox.vue";
import ImpersonateWarning from "@/_view/components/ImpersonateWarning.vue";


@Component({
    components: {
        ImpersonateWarning,
        ShareContentFolderWithTeamsBox,
        FolderInfoBox,
        EditContentFileBox,
        MainBar,
        ShareWithAudienceBox,
        ShareWithTeamsBox,
        EditContentAppBox,
        CreateEditContentFolderBox,
        EntityInfoBox,
        IntroductionBox,
        UpdateBox,
        RoomWidget,
        EntityTranslationBox
    }
})
export default class MainPage extends Vue {

    public cobrowseManager: CobrowseManager = CobrowseManager.getInstance();

}
</script>
