<template>
  <div class="impersonating-warning" v-if="showImpersonationWarning" v-html="$t('BewareImpersonatingUser')" @click="hide"></div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import appUserController from "@/project/user/_controller/AppUserController";

@Component({
    components: {}
})
export default class ImpersonateWarning extends Vue {

    private overrideShowImpersonationWarning: boolean = false;

    get showImpersonationWarning(): boolean {
        return appUserController.inImpersonationMode && !this.overrideShowImpersonationWarning;
    }

    public hide() {
        this.overrideShowImpersonationWarning = true;
    }

}
</script>
